/* TAGS */
body {
  font-family: 'Prompt', sans-serif;
  font-weight: 400;
  background-color: #f9fafb;
}

h1,
h2,
h3,
h4 {
  color: black;
}

p {
  color: black;

}

label {
  font-size: 16px;
  color: black;
}

/* COMPONENTS */
/*A1A1A1*/

.btn-primary {
  color: white;
  background-color: #129137 !important;
  border-color: #129137 !important;
}

.btn-secondary {
  color: white;
  background-color: #47BB11;
  border-color: #47BB11;
  border-radius: 10px;
}

.btn-select-md {
  color: white;
  background-color: #F15A29;
  border-color: #F15A29;
  border-radius: 20px;
  font-size: 18px;
  width: 250px;
}

.btn-select-sm {
  color: white;
  background-color: #F15A29;
  border-color: #F15A29;
  border-radius: 20px;
  font-size: 18px;
  width: 200px;
}

.btn-select-sm:hover {
  color: white;
  background-color: #A1A1A1;
  border-color: #A1A1A1;
  border-radius: 20px;
  font-size: 18px;
  width: 200px;
}


.form-label {
  font-size: 16px;
  color: white;
}

.form-control {
  border: 1px solid white;
  border-radius: 10px;
  font-weight: 300;
}

.form-select {
  border: 1px solid white;
  border-radius: 10px;
  font-weight: 300;
}

.navBar {
  font-size: 20px;
  font-weight: 300;
}

/* PAGE - LOGIN */

.loginBox {
  background-color: #243E7B;
  padding: 50px;
  text-align: center;
  vertical-align: middle;

  position: fixed;
  top: 0;
  left: 0;

  min-width: 100%;
  min-height: 100%;
}

.logo {
  /* margin: 50px; */
  margin: 50px 20px 50px 50px;
  width: 150px;
  height: auto;
}

.mlogo {
  width: 100px;
}


/* PAGE - HOME */

.yamGood {
  background-color: #DDFFD8;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #98D98E;
  margin-top: 20px;
  text-align: center;
}

.yamBad {
  background-color: #FFBDB4;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #FF6D59;
  margin-top: 20px;
  text-align: center;
}

.yamMid {
  background-color: #e8e4bb;
  padding: 20px;
  border-radius: 6px;
  border: 3px solid #f0de12;
  margin-top: 20px;
  text-align: center;
}

.duangBox {
  background-color: #2C2C2C;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #B809CC;
  margin-top: 20px;
  text-align: left;
}

.profileImage {
  width: 100%;
  border-radius: 50%;
  border: 1px solid #B809CC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.homeMenu {
  margin-top: 0px;
  border: 1px solid #B809CC;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* PAGE REGISTER */

.pRegisPdpa {
  font-weight: 300;
  font-size: 12
}

.regisHeader {
  border: 1px solid #B809CC;
  padding: 20px;
  text-align: center;
}

.regisLogo {
  margin: 10px;
  width: 40%;
  height: auto;
}

.regisForm {
  background-color: #f9fafb;
  /* padding: 30px; */

  /* position: fixed;
  top: 0;
  left: 0; */

  min-width: 100%;
  min-height: 100%;
}

.box {
  background-color: #243E7B;
  padding: 50px;

  min-width: 100%;
  min-height: 100%;
}


.regisReferralCodeText {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 300;
}

/* PAGE WALLPAPER */

.wallpaperThumbnail {
  display: block;
  width: 105%;
  border: 2px solid #B809CC;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wallpaperThumbnailNotActive {
  opacity: 0.9;
  display: block;
  width: 105%;
  border: 2px solid #2d3436;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wallpaperBox {
  padding: 20px;
}


.wallpaperImage {
  display: block;
  width: 100%;
  height: auto;
}

.wallpaperNewIcon {
  position: absolute;
  top: 0px;
  width: 68px;
  height: 68px;
}

.wallpaperOverlay {
  border-radius: 6px;
  position: absolute;
  bottom: 2px;
  left: 0px;
  margin-left: 14px;
  margin-right: 8px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.6);
  color: #f1f1f1;
  width: auto;
  transition: .5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  font-size: 12px;
}

.wallpaperDescriptionText {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
}

.wallpaperOrderHeaderText {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.wallpaperShortDescription {
  margin-top: 20px;
  border-radius: 6px;
  border: 2px solid #B809CC;
  padding: 20px;
  background-color: #2d3436;
  color: #B809CC;
  font-size: 18px;
  font-weight: 400;
}

.wallpaperOrderSummaryHeader {
  color: #B809CC;
  font-size: 20px;
  font-weight: 400;
}

.wallpaperOrderSummary {
  margin-top: 20px;
  border-radius: 6px;
  border: 2px solid #B809CC;
  padding: 20px;
  background-color: #2d3436;
  color: #B809CC;
  font-size: 16px;
  font-weight: 300;
}

.wallpaperCategoryHeader {
  padding-top: 20px;
  font-family: 'Prompt', sans-serif;
  color: #B809CC;
  display: flex;
  flex-direction: row;
}

.wallpaperCategoryHeader::before,
.wallpaperCategoryHeader::after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #B809CC;
  margin: auto;
}

/* PAGE ORDERS */

.orderBox {
  padding: 20px;
}

.header {
  margin: auto;
  /* width: 50%; */
  /* padding: 10px; */
  text-align: center;
}

.btn-sml {
  width: 4vh;
  height: 4vh;
}

/* .modal-content {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  background-color: #243E7B;
} */

.btn-doc {
  color: white;
  background-color: white;
  border-color: white;
  border-radius: 10px;
  color: #000;
}

.btn-doc:hover {
  color: white;
  background-color: #A1A1A1;
  border-color: #A1A1A1;
  border-radius: 10px;
  color: #000;
}

.astext {
  position: absolute;
  /* left: 100px; */
  right: 50px;
}

/* Center point */
.center {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: block;
  margin: auto;
}

.login {
  position: absolute;
  left: 50%;
  /* relative to nearest positioned ancestor or body element */
  top: 50%;
  /*  relative to nearest positioned ancestor or body element */
  transform: translate(-50%, -50%);
  /* relative to element's height & width */
}

.right {
  text-align: right;
  display: block;
  justify-content: right;
  align-items: right;
  margin: auto;
}

/* .footer {
  position: absolute;
  bottom: 50px;
  min-width: 100%;

} */


.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  /* background-color: red; */
  color: white;
  /* text-align: center; */
  padding: 50px;
}

.top {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px
}

.border-radius-0 {
  border-radius: 50;
}

.NoBounce {
  touch-action: manipulation;
}



.languageselect {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.languageselect img {
  max-height: 20px;
}

.languageselect div.flag:not(.selected) {
  display: none;
}

.languageselect div.flag.selected {
  display: block;
}

.languageselect:hover div.flag {
  display: block;
}

.front-menu1 {
  position: absolute;
  z-index: 400;
  left: 50%;
  transform: translate(-50%, -50%);
}

.front-menu2 {
  position: absolute;
  z-index: 400;
  left: 50px;
  transform: translate(-50%, -50%);
}

.front-menu3 {
  position: absolute;
  z-index: 400;
  right: 15px;
  transform: translate(-50%, -50%);
}

.pointer-icon {
  width: 40px;
  height: 40px;
}

.pointer {
  position: relative;
  width: 100%;
  height: 100%;
}

.pointer::before,
.pointer::after {
  position: absolute;
  content: '';
  background-color: red;
}

.pointer::before {
  top: 50%;
  left: 10%;
  right: 10%;
  height: 2px;
}

.pointer::after {
  top: 10%;
  bottom: 10%;
  left: 50%;
  width: 2px;
}

.pointStyle {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: '10px';
  height: '10px';
  /* borderRadius: '50%'; */
  /* backgroundColor: 'red'; */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}

.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee
}

.card {
  height: 100%;
  width: 350px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Prompt', sans-serif;
  border-radius: 10px;
  padding: 20px;
  opacity: 0.95;
}

.card-resgiter {
  height: 600px;
  width: 350px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  font-family: 'Prompt', sans-serif;
  border-radius: 10px;
  padding: 20px;
  opacity: 0.95;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center
}

.text p {
  font-size: 13px;
  color: #bac4d4;
  font-weight: 700
}

.input-text {
  position: relative;
  margin-top: 15px
}

input[type="text"] {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  border: 1px solid #e0e3e9;
  padding: 0 10px;
  padding-left: 30px;
  outline: 0;
  font-size: 13px
}

input[type="password"] {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 9px;
  border: 1px solid #e0e3e9;
  padding: 0 10px;
  padding-right: 27px;
  padding-left: 30px;
  outline: 0;
  font-size: 13px;
}

.input-text .fa-eye-slash {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer
}

.fa-eye {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer
}

.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center
}

.buttons button {
  height: 45px;
  width: 100%;
  border: none;
  background-color: #129137;
  border-radius: 9px;
  font-size: 12px;
  outline: 0;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s
}

.buttons button:hover {
  background-color: #10762e
}

.buttons-back {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center
}

.buttons-back button {
  height: 45px;
  width: 100%;
  border: none;
  background-color: #FF6D59;
  border-radius: 9px;
  font-size: 12px;
  outline: 0;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s
}

.buttons-back button:hover {
  background-color: #FF6D59
}

.forgot {
  margin-top: 30px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center
}

.forgot a {
  text-decoration: none
}

.warning {
  border: 1px solid red !important
}

.type_password {
  border: 1px solid red !important
}

#background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('../src/assets/images/rice-560051.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sticky-button {
  position: fixed;
  bottom: 40px;
  left: 80%;
  transform: translateX(-50%);
}

.maps-header {
  background-color: #016545;
  border-radius: 0 0 20px 20px /10px;
  margin: 0;
  padding: 30px 20px 10px 20px;
  background-image: url(../src/assets/images/rice-560051_1280.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
}

.circle-icon {
  border: 1px solid black;
  background-color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add any additional styles you need for the circle icon */
}

.leaflet-container .leaflet-control-attribution {
  display: none;
}

.calendar-box {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.timeline_area {
  position: relative;
  z-index: 1;
}

.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}

@media only screen and (max-width: 575px) {
  .single-timeline-area {
    padding-left: 100px;
  }
}

.single-timeline-area .timeline-date {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}

@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 120px;
  }
}

.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: #ebebeb;
  top: 0;
  right: 30px;
  z-index: 1;
}

.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #016545;
  content: "";
  top: 50%;
  right: 26px;
  z-index: 5;
  margin-top: -5.5px;
}

.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #020710;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 30px 30px 25px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
    margin: 10px;
    padding: 20px;
  }
}

.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.single-timeline-area .single-timeline-content .timeline-icon i {
  color: #ffffff;
  line-height: 30px;
}

.single-timeline-area .single-timeline-content .timeline-text h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
}

.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
  background-color: #020710;
}

.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
  color: #3f43fd;
}

.badge-type-growth {
  background-color: #129137 !important;
}

.badge-type-weed {
  background-color: #FFC107 !important;
}

.badge-type-fertilizer {
  background-color: #F9F !important;
}

.badge-type-bug {
  background-color: #3CF !important;
}

.new-home-box {
  margin: 1rem;
}